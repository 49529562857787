import { Typography } from "@mui/material";
import React, { useState } from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import BasicTable from "../../components/Table";
import {
  orderResponseJsonExample,
  orderStatusHead,
  orderStatusRowsV1,
  orderStatusRowsV2,
  listOrderEndpoints,
  listOrderJsonExample,
} from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import {
  codeFont,
  listStyle,
  listItems,
  pageStyles,
  pageContent,
  body,
} from "../../styleConstants";
import VersionSwitch from "../../components/VersionSwitch";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import PageTitle from "../../components/PageComponents/PageTitle";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={5} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="List Order" />

          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />
          <Paragraph
            text="You can get a list of all orders created by the same API key with
            the request below."
          />
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorderslist" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorderslist" />
          )}
          <Paragraph
            text='The List Orders endpoint supports optional filtering on the order
            "status" and delivery data types "fileDataType"
            with the following query parameters to be included in the request
            body:'
          />
          <ul style={listStyle}>
            {listOrderEndpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}
          </ul>
          <Typography variant="body1" style={body} sx={{ marginTop: "50px" }}>
            If the{" "}
            <code style={codeFont}>status, fileDataType, limit, and page</code>{" "}
            &nbsp; were not specified, all orders made by this API key will be
            listed regardless of their status or delivery file data type.
          </Typography>

          {index === 0 ? (
            <>
              <ParagraphTitle text="Order Status" />
              <Paragraph text="The following statuses can be used while filtering with the getOrderList" />

              <BasicTable head={orderStatusHead} rows={orderStatusRowsV1} />
            </>
          ) : (
            <>
              <ParagraphTitle text="The available order 'status' options to filter with" />
              <Paragraph text="The following statuses can be used while filtering with the getOrderList" />

              <BasicTable head={orderStatusHead} rows={orderStatusRowsV2} />
            </>
          )}
          <ParagraphTitle text="Example Request" />
          {index === 0 ? (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorderslist" />
          ) : (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorderslist" />
          )}
          <JsonContainer text={listOrderJsonExample} />

          <ParagraphTitle text="Example Response" />
          <JsonContainer text={orderResponseJsonExample} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
